import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Header from "../components/header"
// import ImageList from "../components/image-list"

import "../styles/index.scss"

const IndexPage = ({ data }) => {
  // console.log("data", data)

  const systems = data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => (
      <Link
        key={edge.node.id}
        to={edge.node.frontmatter.path}
        className="home__system-link"
      >
        {edge.node.frontmatter.title} >
      </Link>
    ))

  const logos = ["lime-crm-logo.png", "lime-go-logo.png", "pipedrive-logo.png"]
  const filteredImages = data.allImageSharp.edges
    .filter(edge => logos.includes(edge.node.fixed.originalName))
    .map(imageObj => (
      <Img key={imageObj.node.fixed.originalName} fixed={imageObj.node.fixed} />
    ))

  return (
    <Layout>
      <SEO
        title="Boka demo med rätt experter"
        description="Istället för att du skall jaga och se över hela marknaden om bra
      leverantörer så kan du hitta pålitliga och seriösa aktörer här på
      bokademo.nu"
      />
      <Header siteTitle="Boka demo nu" />
      {/** {data.site.siteMetadata.title} */}
      <div className="home__page-header">
        <div className="home__page-header__text">
          <h1 className="home__title">Boka demo med rätt experter</h1>
          <p className="home__subtitle">
            Vi hjälper dig att matcha system med rätt experter
          </p>
        </div>

        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            height: "12vw",
          }}
        >
          <path
            fill="#ddd"
            d="M 0, 100
                C 24, -19 73, 118 100, 2
                L 100, 100
                L 0, 100
                L 0, 77
                Z"
          />
        </svg>
        <svg
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            height: "10vw",
          }}
        >
          <path
            fill="#fff"
            d="M 0, 100
                C 24, -19 73, 118 100, 2
                L 100, 100
                L 0, 100
                L 0, 77
                Z"
          />
        </svg>
      </div>

      <div className="home__section home__section--small">
        <h2>Varför?</h2>

        <h3>Låt inte leverantören fylla din mailkorg</h3>
        <p>
          Vi ser till att du inte blir mailbombad av flera leverantörer. Vi
          lagrar dina uppgifter enligt GDPR och skall användes endast för att du
          skall få en demo.
        </p>

        <h3>Möjlighet att boka demo med företag</h3>
        <p>
          Istället för att du skall jaga och se över hela marknaden om bra
          leverantörer så kan du hitta pålitliga och seriösa aktörer här på
          bokademo.nu samt via vår samarbetspartner{" "}
          <a href="https://businesswith.se">businesswith.se</a>. Vi samarbetar
          endast med seriösa aktörer som levererar de bästa anpassade demon för
          de som är intresserade.
        </p>

        <h3>Vi ser till att du får de bästa experterna för en bra demo</h3>
        <p>Vi ställer krav på systemleverantörerna!</p>
        <p>
          Har man lagt tid på att begära en demo så skall den utföras av deras
          bästa experter så att tiden spenderas väl. Därför har vi utvalda
          mottagare hos våra leverantörer som skall ge er bra service.
        </p>

        <h3>Kvalitetssäkrar bra behandling från leverantörer</h3>
        <p>
          Om du upplever dålig behandling så låt oss veta så att vi kan hantera
          det med leverantören och se till att de uppfyller våra krav.
        </p>
      </div>

      <div className="home__section home__section--small">
        <div className="home__logos">{filteredImages}</div>
      </div>

      {/**
       */}
      <div className="home__section-bg">
        <div className="home__section home__section--small">
          <h2 style={{ marginBottom: "2rem" }}>Vilka?</h2>
          <div>System hos oss: {systems}</div>
          {/* <Link to="/systems/">Alla systemen</Link> */}
        </div>
      </div>

      <div className="home__section home__section--small">
        <h2 style={{ marginBottom: "2rem" }}>Vill du också synas här?</h2>
        <p>
          Kontakta oss på telefon nr: 073-394 04 61‬ eller kontakta oss på{" "}
          <a href="mailto:info@bokademo.nu">info@bokademo.nu</a>
        </p>
      </div>

      {/*
      <section>
        <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
          <Image />
        </div>
      </section>
      */}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            logo
          }
        }
      }
    }
    allImageSharp {
      edges {
        node {
          fixed(height: 60, grayscale: true) {
            originalName
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  }
`
